@charset "utf-8";
/* CSS Document */

.smallGroupedItemsContainer {
  margin:18px 0 6px 0;
}

.smallGroupedItemsContainer-Row {
  margin:3px 7px 6px 0;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.smallGroupedItemsContainer-Condensed {
  margin:8px 0 0px 0;
}

.dateCreatedDisplayDateContainer {
	display:flex;
	flex-direction: row;
	
	
}

.dateCreatedDisplayDateContainer-Condensed {
	display:flex;
	flex-direction: row;
	justify-content: space-between;
  
	
}

.dateCreatedDisplayDateContainer-Condensed-Column {
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	
}

.displayDateInstallService-Condensed {
  display: flex;
	flex-direction: row;
	justify-content: center;
  color:#212121;
	font-size: 15px;
	text-align: center;
  font-weight: 400;
  text-decoration: none;
	letter-spacing: 1px;
	border-radius: 8px;
  padding: 3px 10px 2px 2px;
  height: 18px;
  line-height: 18px;
  margin-top:-3px;
  border-radius:18px;
  background-color: #ffffff;
}

.dateLabelStackedwIcon {
	height: 22px;
	color: #212121;
	line-height: 22px;
	text-align: left;
	font-size: 12px;
	margin-left: 2px;
	padding-right: 12px;
  padding-left: 12px;
  border-radius: 12px 12px 12px 12px;
  margin-left:29px;
}



.displayDate {
	display: flex;
	flex-direction: row;
	justify-content: center;
  color:#dcf9ca;
  background-color:#2D8239;
  padding: 3px 12px 2px 12px;
  height: 16px;
  line-height: 16px;
	font-size: 13px;
	text-align: center;
  font-weight: 400;
  text-decoration: none;
  border-width: 1px 0px 1px 0px; 
  box-shadow: inset 0px 0px 6px rgba(0,0,0,0.2);
  border-style: solid;
	border-color:#8EA480;
	border-radius: 8px;
	width: 170px;
	letter-spacing: 1px;
}


.displayDate-Condensed {
	display: flex;
	flex-direction: row;
	justify-content: center;
  color:#dcf9ca;
  background-color:#2D8239;
  padding: 3px 10px 2px 10px;
  height: 16px;
  line-height: 16px;
	font-size: 13px;
	text-align: center;
  font-weight: 400;
  text-decoration: none;
  border-width: 1px 0px 1px 0px; 
  box-shadow: inset 0px 0px 6px rgba(0,0,0,0.2);
  border-style: solid;
	border-color:#8EA480;
	border-radius: 8px;
	width: 170px;
	letter-spacing: 1px;
}

.displayDateInstallService-Condensed {
  display: flex;
	flex-direction: row;
	justify-content: center;
  color:#212121;
	font-size: 15px;
	text-align: center;
  font-weight: 400;
  text-decoration: none;
	letter-spacing: 1px;
	border-radius: 8px;
  padding: 3px 10px 2px 2px;
  height: 18px;
  line-height: 18px;
  margin-top:-3px;
  border-radius:18px;
  background-color: #ffffff;
}

.displayDate-Condensed-Closed {
	
  color:#000000;
  background-color:#f2f2f2;
  box-shadow: inset 0px 0px 6px #f2f2f2;
  border-style: solid;
	border-color:#fff;
}

.commentLabel {
  color: #000000!important;
  line-height: 20px;
  font-size: 14px;
  text-decoration: none;
  
  .commentLabelText{
    background-color: #1c7a17;
    color: #fff;
    border: 2px solid #1c7a17;
  }
}

.StatusCLOSED-X {
	position:relative !important;
  top:3px !important;
  width: 16px !important;
  color:#4a4b4a !important;
}

#entitledBarContainerWrapper {
  z-index: 6;
  position: fixed;
  top: 130px;
  width: 100%;
  height: 35px;
  white-space: nowrap;
  background-color: #f2f2f2;
}

.flexwrapper1280 {
  display: flex;
  justify-content: start;
  flex-direction: row;
  height: 35px;
  margin-left: 0px;
}

.entitleitem-leftMargin {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
  width: 50px;
}

/*  1 PARENT FLEX CONTAINER - 3 ITEMS  */
.entitledBarContainer {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-direction: row; /*flex-direction flex-wrap*/
  height: 35px;
  margin-left: 130px;
  width: 1220px;
}

.entitleItem1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.entitledLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #1c7a17;
  height: 26px;
  font-family: FormaDJRMicro-BoldItalic;
  line-height: 26px;
  font-size: 14px;
  text-decoration: none;
  padding: 0px 1px 0px 2px;
  margin: 2px 15px 0px 12px;
}

.entitledLabelCheck {
  position: relative;
  top: 2px;
  left: 9px;
  background-color: #1c7a17;
  width: 20px;
  height: 20px;
  font-family: FormaDJRMicro-Regular;
  font-size: 10px;
  line-height: 20px;
  color: #ffffff;
  margin-left: 0px;
  text-align: center;
  border-radius: 8px;
  border-color: #1c7a1710;
  border-style: solid 8px;
  box-shadow: 0px 0px 10px rgba(28, 122, 23, 0.2);
}

.entitledLabelCrossCheck {
  position: relative;
  top: 2px;
  left: 9px;
  background-color: $red;
  width: 20px;
  height: 20px;
  font-family: FormaDJRMicro-Regular;
  font-size: 10px;
  line-height: 20px;
  color: #ffffff;
  margin-left: 0px;
  text-align: center;
  border-radius: 8px;
  border-color: #1c7a1710;
  border-style: solid 8px;
  box-shadow: 0px 0px 10px rgba(28, 122, 23, 0.2);
}

.entitleItem2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.installationLabel {
  display: flex;
  flex-direction: row;
  color: #212121;
  background: #ffffff;
  height: 26px;
  font-family: FormaDJRMicro-Medium;
  line-height: 26px;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
  padding: 0em 1em 0em 1em;
  border-radius: 8px 0px 0px 8px;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #8ea480;
  margin-left: 6px;
}

.installationLabelDates {
  background-color: #2d8239;
  height: 26px;
  font-family: FormaDJRMicro-Medium;
  font-size: 14px;
  line-height: 26px;
  color: #dcf9ca;
  border-radius: 0px 8px 8px 0px;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #8ea480;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.2);
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 15px;
  padding-right: 15px;
}

.entitleItem3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.serviceLabel {
  display: flex;
  flex-direction: row;
  color: #212121;
  background: #ffffff;
  height: 26px;
  font-family: FormaDJRMicro-Medium;
  line-height: 26px;
  font-size: 12px;
  text-decoration: none;
  padding: 0em 1em 0em 1em;
  border-radius: 8px 0px 0px 8px;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #8ea480;
  margin-left: 6px;
}

.serviceLabelDates {
  background-color:#2D8239;
	height:26px;
	font-family: FormaDJRMicro-Medium;
	font-size: 14px;
	line-height: 26px;
	font-weight: normal;
	color:#dcf9ca;
	border-radius: 0px 8px 8px 0px; 
	border-width: 1px 1px 1px 0px;
	border-style: solid;
	border-color:#8EA480;
	box-shadow: inset 0px 0px 6px rgba(0,0,0,0.2);
	margin-left:0px;
	margin-right: 0px;
	padding-left:15px;
	padding-right:15px;
}
