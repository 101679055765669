@font-face {
    font-family: 'FormaDJRMicro-Medium';
    src: url('../../fonts/rsc/fonts/FormaDJRMicro-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FormaDJRMicro-MediumItalic';
    src: url('../../fonts/rsc/fonts/FormaDJRMicro-MediumItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FormaDJRMicro-BoldItalic';
    src: url('../../fonts/rsc/fonts/FormaDJRMicro-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'FormaDJRMicro-Bold';
    src: url('../../fonts/rsc/fonts/FormaDJRMicro-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FormaDJRMicro-Regular';
    src: url('../../fonts/rsc/fonts/FormaDJRMicro-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FormaDJRMicro-Italic';
    src: url('../../fonts/rsc/fonts/FormaDJRMicro-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FormaDJRMicro-Light';
    src: url('../../fonts/rsc/fonts/FormaDJRMicro-Light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
background-color: #f8f8f8 !important;	
}



p {
 font-family: FormaDJRMicro-Regular; 
}

a { 
   color: #027AAE; text-decoration: none;
 }

 a:hover {
 	color: #035C84; text-decoration: none;
 }

 a:active {
 	color: #014667; text-decoration: none;
 }


.forms {
	font-family: calibri;
	height: 26;
}



