#navContainerWrapper {
  z-index: 7;
  position: fixed;
  top: 65px;
  width: 100%;
  margin: auto;
  background-color: #f8f8f8;  
}

.navContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: baseline;
  height: 130px;
  font-size: 18px;
  margin-left: 240px;
  margin-top: 20px;
  width:"100%";
  background-color: #f8f8f8;
}

.css-8atqhb{
  width: auto !important;
}

.vn-tab-list__tab{
  height: 80px !important;
}