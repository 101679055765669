#footerContainerWrapper { 
	position:fixed; 
	display: flex; 
	justify-content: center; 
	flex-direction:row; 
	align-items: center;
	bottom:0px; 
	width: 100%; 
	height:30px;
	font-family: FormaDJRMicro-Regular; 
	font-size:13px;
	background: #0096D6; 
	color: #ffffff;
	margin:auto;
}