
@media only screen and (max-width: 600px) {

  .HeaderLogo{
      width: 40px;
      height: 40px;
      margin: 5px;
      margin-left: 0px;
      border-radius: 500px
  }

  .overlayShow {
      background-color: black !important;
      position: fixed;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      background-image: url(Images/PrinterBackground.jpg);
      top: 0px;
      left: 0px;

  }

  .loginHeaderClass {
      padding: 30px !important;
      width: 100% !important;
      margin: 0 auto !important;
      text-align: center;
  }

  .loginHeaderText {
      padding-top: 0.25em;
      text-align: center;
      color: #fafafa;
      font-family: "HP Simplified";
      font-weight: 900;
      font-size: 30px;
      text-shadow: 0px -3px 2px #547c8f;
  }


  .contentShow {
      margin-top: 2em;
      flex-grow: 1;
      transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      margin-left: 10%;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
  }

  .loginContent {
      border: blue !important;
  }

  .loginHeaderH2 {
      color: #fafafa;
      font-family: "HP Simplified";
      font-weight: 400;
      padding: 2px;
      font-size: 1.75rem;
      margin-bottom: 1em;

  }

  .loginHeaderH5 {
      color: #c8c8c8;;
      font-family: "HP Simplified";
      font-weight: 460;
      padding: 0 0 1em;

  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {

  .HeaderLogo{
      width: 40px;
      height: 40px;
      margin: 5px;
      margin-left: 0px;
      border-radius: 500px
  }


  .overlayShow {
      background-color: black !important;
      position: fixed;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      background-image: url(Images/PrinterBackground.jpg);
      top: 0px;
      left: 0px;

  }

  .loginHeaderClass {
      text-align: center;
      padding: 30px !important;
      width: 100% !important;
      margin: 0 auto !important;
  }

  .loginHeaderText {
      padding-top: 0.25em;
      text-align: center;
      color: #fafafa;
      font-family: "HP Simplified";
      font-weight: 900;
      font-size: 36px;
      text-shadow: 0px -3px 2px #547c8f;
  }


  .contentShow {
      margin-top: 2em;
      flex-grow: 1;
      transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      margin-left: 10%;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
  }

  .loginContent {
      border: blue !important;
  }

  .loginHeaderH2 {
      color: #fafafa;
      font-family: "HP Simplified";
      font-weight: 400;
      padding: 2px;
      font-size: 1.75rem;
      margin-bottom: 1em;

  }

  .loginHeaderH5 {
      color: #c8c8c8;;
      font-family: "HP Simplified";
      font-weight: 460;
      padding: 0 0 1em;

  }
}

@media only screen and (min-width: 769px) and (max-width: 1029px) {

  .overlayShow {
      background-color: black !important;
      position: fixed;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      background-image: url(Images/PrinterBackground.jpg);
      top: 0px;
      left: 0px;

  }

  .loginHeaderClass {
      text-align: center;
      padding: 30px !important;
      width: 100% !important;
      margin: 0 auto !important;
  }

  .loginHeaderText {
      padding-top: 0.25em;
      text-align: center;
      color: #fafafa;
      font-family: "HP Simplified";
      font-weight: 900;
      font-size: 36px;
      text-shadow: 0px -3px 2px #547c8f;
  }


  .contentShow {
      margin-top: 2em;
      flex-grow: 1;
      transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      margin-left: 10%;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
  }

  .loginContent {
      border: blue !important;
  }

  .loginHeaderH2 {
      color: #fafafa;
      font-family: "HP Simplified";
      font-weight: 400;
      padding: 2px;
      font-size: 1.75rem;
      margin-bottom: 1em;

  }

  .loginHeaderH5 {
      color: #c8c8c8;;
      font-family: "HP Simplified";
      font-weight: 460;
      padding: 0 0 1em;

  }
}

@media only screen and (min-width: 1030px) {

  .overlayShow {
      background-color: black !important;
      position: fixed;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      background-image: url(Images/PrinterBackground.jpg);
      top: 0px;
      left: 0px;

  }

  .loginHeaderClass {
      padding: 30px !important;
      width: 50% !important;
      margin: 0 auto !important;
      text-align: center;
  }

  .loginHeaderText {
      text-align: center;
      color: #fafafa;
      font-family: "HP Simplified";
      font-weight: 900;
      font-size: 42px;
      text-shadow: 0px -3px 2px #547c8f;
  }


  .contentShow {
      margin-top: 5em;
      flex-grow: 1;
      transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      margin-left: 15em;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
  }

  .loginContent {
      border: blue !important;
  }

  .loginHeaderH2 {
      color: #e8e8e8;
      font-family: "HP Simplified";
      font-size: 1.75rem;
      font-weight: 400;
      margin: 0 0 1em;

  }

  .loginHeaderH5 {
      color: #c8c8c8;
      font-family: "HP Simplified";
      font-weight: 460;
      margin: 0 0 1.5em;

  }
}

.post {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 15px;
  margin: 20px auto;
  max-width: 900px;
  background-color: #fff;
}

.help-block {
  font-size: 12px;
}

pre{
  font-size: 1.2em !important;
}
