@charset "utf-8";
/* CSS Document */

#searchPaginationBarContainerWrapper {
  z-index: 1;
  width: 100%;
  height:50px;
  white-space: nowrap;
  padding-top: 100px;
	padding-left: 340px;
}

/*  1 PARENT FLEX CONTAINER - 3 ITEMS  */
#searchPaginationBarContainer {
  display: flex; 
	flex-direction: row;
	justify-content: center; 
	flex-direction:row; /*flex-direction flex-wrap*/
	height:55px; 
	margin-left: 250px;
}

.searchItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pageItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 400px;
}

.searchResultsLabel {
  display: flex;
	flex-direction: row;
	justify-content: center;
	text-align: center;
  color: #212121;
  height: 26px;
  font-family: FormaDJRMicro-Medium;
  line-height: 26px;
	font-size: 18px;
	margin-top: 6px; 
	margin-bottom: 6px;
	margin-left: 0px;
	margin-right:8px;
}

.searchResultsType {
  display: flex;
	direction: row;
	margin-right:8px;
	font-family: FormaDJRMicro-Regular;
	font-size: 18px;
}

.searchResultsTypeNumber {
  font-family: FormaDJRMicro-Medium;
	color: #3CAE4C;
	margin-right: 25px;
	font-size: 18px;
}

.searchPaginationContainers {
  display: flex;
	flex-direction: row;
	justify-content: center;
	text-align: center;
  color: #212121;
  height: 26px;
  width: 26px;
  font-family: FormaDJRMicro-Medium;
  line-height: 26px;
	font-size: 16px;
  text-decoration: none;
  border-radius: 8px; 
	border: none;
	margin: 6px;
}

.searchPaginationContainers:hover {
  background-color: #027AAE;
  color: #ffffff;
  text-decoration: none;
}

