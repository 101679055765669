@charset "utf-8";
/* CSS Document */


.itsmK2 {
  background-color: rgba(28, 122, 23, 0.702);
  border-radius: 6px;
  border-width: 1px;
  border-style:double;
  border-color: #ffffff;
  height: 60px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  color:#ffffff;
  letter-spacing: 1px;
  padding:5px 10px;
  margin:0 20px 0 0;
}

.itsmContract {
  background-color: rgba(28, 122, 23, 0.702);
  border-radius: 6px;
  border-width: 1px;
  border-style:double;
  border-color: #ffffff;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  color:#ffffff;
  letter-spacing: 1px;
  padding:5px 10px;
  margin:0 20px 0 0;
}

.StatusActiveOpenEntitled-Container {
	position: relative;
	background-color: #EFF7EB;
	display: flex;
	justify-content: center;
	flex-direction:row;
	align-items: center;
  align-content: center;
	color: #212121;
  background: #1C7A1710;
  height: 22px;
  padding: 0px 7px 0px 12px;
  border-radius: 12px;
}

.StatusActiveOpenEntitled-Check {
  top:2px !important;
  width: 16px !important;
  color:#1C7A17 !important;
}

.StatusActiveOpenEntitled-CrossCheck {
	position:relative !important;
  top:2px !important;
  width: 18px !important;
  color:red !important;
}

.StatusActiveOpenEntitled-Container {
  position: relative;
  background-color: #EFF7EB;
  display: flex;
  justify-content: center;
  flex-direction:row;
  align-items: center;
  align-content: center;
  color: #212121;
  background: #1C7A1710;
  height: auto; 
  padding: 6px; 
  border-radius: 12px;
  word-wrap: break-word;
  margin: 10px 0; 
  max-width: 100%;
}

.StatusCLOSED-Container {
	position: relative;
	background-color: #f2f2f2;
	display: flex;
	justify-content: center;
	flex-direction:row;
	align-items: center;
  align-content: center;
	color: #212121;
  height: 22px;
  padding: 0px 7px 0px 12px;
  border-radius: 12px;
}


.StatusActiveOpenEntitled-Container-Operational {
	width: 260px;
	margin-left: 22px;
}

@mixin StatusActiveOpenEntitled-Label {
  line-height: 24px;
  font-size: 14px;
  text-decoration: none;
  margin: 0px 8px 0 0px;
  white-space: normal;
  word-wrap: break-word;
}

.StatusActiveOpenEntitled-Label {
  @include StatusActiveOpenEntitled-Label;
}

.StatusActiveOpenEntitled-Label--specific {
  @include StatusActiveOpenEntitled-Label;
  font-size: 13px;
  margin: 0px;
  margin-top: inherit;
}

.disabled {
  opacity: 60%;
}

.cardContainerSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.cardContainerSpaceAround {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.cardContainerRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.smallCardLabel {
  color: #000000!important;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  text-decoration: none;
  padding: 0px 0px 0px 0px;
  margin: 0px 10px 0px 0px;
  border-width: 0px 0px 0px 0px;
  border-style: none;
}

.smallCardLabel-Condensed {
  height: auto;
  line-height: 18px;
}

.smallCardLabelData {
  color: #212121;
  height: 18px !important;
  line-height: 18px !important;
  font-size: 13px !important;
  text-decoration: none;
  padding: 0px 0px 0px 3px;
  margin: 0px 0px 0px 3px;
  border-width: 0px 0px 0px 0px;
  border-style: none;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-all;
  letter-spacing: 1px;
}

.smallCardLabelData-Condensed {
  height: 18px !important;
  line-height: 18px !important;
}

.modelName{
  white-space: normal;
  overflow-wrap: break-word;
}


.customerName {
  white-space: normal;
  overflow-wrap: break-word;
}

.subtitle-regular.cardTitle {
  margin-bottom:22px;
  align-items: center;
  font-weight: 550;
}


.hr {
  width:100%;
  border-top:1px solid #c3c3c350;
  border-right: 0px;
  border-bottom: 0px;
  border-left: 0px;
  height:1px;
  justify-content: center;

}
