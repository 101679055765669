#headerContainerWrapper {
  z-index: 99;
	position:fixed;
	top:0px;
	width: 100%;
	height:68px;
	background: #ffffff;
	margin:auto;
}

.logoTitle {
  white-space: nowrap;
}

/*  1 PARENT FLEX CONTAINER - 3 ITEMS  */
.headerContainer {
  display: flex;
	justify-content: space-between;
	flex-flow:row nowrap; /*flex-direction flex-wrap*/
	align-items: center;
	height:68px;
	font-size:20px;
	margin:auto;
}

/*  1 CHILD FLEX CONTAINER - 1 of 3 ITEMS  */
.headerItem {
  align-items: center;
	justify-content: center;
  margin-left: 100px;
}
.headerHPLogoNameContainer {
  display: flex;
	flex-flow:row nowrap;
	align-self: center;
	justify-content: center;
	text-align:center;
	margin:auto;
	height:68px;
	line-height: 68px;
	font-size: 20px;
	font-family: FormaDJRMicro-Medium;
}

.headerHPLogoNameContainer img {
  width: 68px;
  height: 68px;
}

/*  1 CHILD FLEX CONTAINER - 2 of 3 ITEMS  */
.headerItem2 {
  align-self: center;
}

.searchNavContainer {
  display: flex;
	flex-flow:row nowrap;
	align-items: center;
	justify-content: center;
	text-align:left;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.menu-area a {
  display: flex;
  align-items: left;
  justify-content: left;
  color: #737373;
  background: #ffffff;
  height: 48px;
  font-family: FormaDJRMicro-Regular;
	font-size: 16px;
  font-weight: normal;
  text-decoration: none;
  border-radius: 12px 0px 0px 12px;

}
.menu-area a:hover{
  background: #ffffff;
  color: #ffffff;
  border-radius: 0px 0px 0px 0px;

}

.menu-area ul {
  list-style: none;
  display: flex;
}
.menu-area li {
  position: relative;
  text-align: left;
}
.menu-area li:hover .regionDropdown > li {
  display: block;
  top: 0px;
  width: 100%;
  transition-duration: 0.5s;
}

.regionDropdown {
  background-color: #0096d6;
}

#dropdownColor {
  background-color: #0096d6 !important;
  border-radius: 6px 0px 0px 6px;
  box-shadow: none ;
  height: 32px !important;
  padding-top: 0.245rem !important;
  padding-bottom: 0.245rem !important;
}

#searchFieldContainer {
  position: relative;
  display: flex;
  min-width: 400px;
  height:48px;
  border: 0px;
}

#search {
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #ADADAD;
  border-radius: 12px 0px 0px 12px;
  height: 48px;
  width: 400px;
  padding: 0px 23px 0px 15px;
  outline: 0;
  background-color: #FFFFFF;
  font-family: FormaDJRMicro-Regular;
  font-size: 14px;
}

.search:hover,
.search:focus {
  border: 1px solid #72D2FB;
  background-color: white;
}

.changeRegionReturnLabel {
  font-family: FormaDJRMicro-Regular;
  background-color:#1C7A17;
  height: 48px;
  line-height:48px;
  padding-left:10px;
  padding-right:10px;
  color:#fff;
  margin-right:15px;
  border-radius:0px 12px 12px 0px;
}

/* Dropdown Button */
#dropbtnSelectRegion {
  background-color: #ffffff;
  background-image: url("../../../Images/vnr-right-arrow.svg");
  background-repeat: no-repeat;
  background-position: 130px 15px;
  background-size: 16px;
  font-family: FormaDJRMicro-Medium;
  color: #737373;
  line-height: 48px;
  font-size: 16px;
  border: solid 1px;
  border-color: #ADADAD;
  border-radius: 12px 0px 0px 12px;
  padding-left: 15px;
  padding-right: 60px;
  height: 48px;
  z-index: 11;
  white-space: nowrap;
}

#dropbtnSelectRegionDisabled {
  background-color: #ADADAD;
  background-image: url("../../../Images/vnr-right-arrow.svg");
  background-repeat: no-repeat;
  background-position: 140px 15px;
  background-size: 16px;
  font-family: FormaDJRMicro-Medium;
  color: #737373;
  line-height: 48px;
  font-size: 16px;
  border: solid 1px;
  border-color: #ADADAD;
  border-radius: 12px 0px 0px 12px;
  padding-left: 15px;
  padding-right: 60px;
  height: 48px;
  z-index: 11;
  white-space: nowrap;
}



#dropbtnSelectRegion:hover {
  background-image: url("../../../Images/vnr-dwn-arrow.svg");

}

/* The container <div> - needed to position the dropdown content */
.dropdownSelectRegion {
  position: relative; /* 6px is required to align the Change Region button to align vertically to the default region and the Change bar */
  display: inline-block;
  text-align: left;
  border-radius: 12px 0px 0px 12px;
}

.dropdownSelectType {
  // padding: 0 30px 0 15px;
  background-position: 105px 15px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-contentSelectRegion {
  display: none;
  position: absolute;
  left: 0px;
  text-align: left;
  left: 12px;
  width: 176px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
   border-radius: 0px 0px 12px 12px;
  z-index: 10;
}

/* Links inside the dropdown */
.dropdown-contentSelectRegion a {
  color: #404040;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 0px 0px 0px 0px;
}

.dropdown-contentSelectRegionEMEA a {

  border-radius: 0px 0px 12px 12px;
}

/* Change color of dropdown links on hover */
.dropdown-contentSelectRegion a:hover {background-color:#014768;}

/* Show the dropdown menu on hover */
.dropdownSelectRegion:hover .dropdown-contentSelectRegion {display: block; background-color:#014768; border-radius:0px 0px 12px 12px;}

/* Change the background color of the dropdown button when the dropdown content is shown #3CAE4C */
.dropdownSelectRegin:hover .dropbtnSelectRegion {background-color:#014768; border-radius: 6px 0px 0px 6px;}

.goButton {
  z-index: 2000;
  position: relative;
  height:48px;
  width: 48px;
  background-color: #ffffff;
  background-image: url("../../../Images/search-magnify-glass.svg");
  background-repeat: no-repeat;
  background-position: 10px 14px;
  background-size: 17.5px;
  color: #737373;
  border-radius: 0px 12px 12px 0px;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #ADADAD;
    text-decoration: none;
    text-align: center;
  cursor: pointer;
}

/*  1 CHILD FLEX CONTAINER - 3 of 3 ITEMS  */
.headerItem3 {
  display: flex;
	align-items: center;
	align-self: center;
	height: 65px;
	text-align: right;
  margin-right: 50px;
}

.userLoginButtonContainer {
	display: flex;
	flex-flow:row nowrap;
	align-items: center;
	justify-content: center;
	text-align:center;
	line-height: 65px;
}

.userLoginContainer img{
  height: 65px;
	line-height: 65px;
	align-items: center;
	justify-content: center;
}



.userFlexDropDownContainer {
  display:flex;
  flex-direction: row;
  line-height: 30px;
  justify-content: right;
  justify-items: left;
  text-align: left;
}

/* Dropdown Button */
.dropbtn {
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 6px 0px 0px 6px;
  font-family:FormaDJRMicro-Regular;
  font-size: 16px;
  border: none;
  justify-content: left;
  justify-items: left;
  text-align: left;
  z-index: 11;
}

.dropbtn img {
  width: 35px;
  height: 48px;
  margin-left: 0px;
  vertical-align: middle;
}

.userLoginButtonContainer,
.userLoginButtonContainer .flexDropDownContainer,
.userLoginButtonContainer .flexDropDownContainer .dropdownUserName {
  height: 100%;
}

.dropbtnUserName {
  color: #027AAE;
  padding-right: 20px;
  font-size: 14px;
  border: none;
  vertical-align: middle;
  justify-content: left;
  justify-items: left;
  text-align: left;
  z-index: 11;
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
  display: flex;
  align-items: center;
  justify-content: left;
  justify-items: left;
  text-align: left;
}

.dropdownUserName {
  position: relative;
  padding-bottom: 5px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-contentUserName {
  display: none;
  overflow: hidden;
  flex-direction: column;
  justify-content: left;
  justify-items: left;
  text-align: left;
  position: absolute;
  background: #ffffff;
  min-width: 160px;
  line-height: 30px;
  text-align: left;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.4);
  border-radius: 12px 0px 12px 12px;
  z-index: 10;
}
.userLoginButtonContainer:hover .dropdown-contentUserName {
  display: block;
}

.dropdown-contentUserName {
  position: absolute;
  top: 100%;
  right: 0;
  padding-bottom: 5px;
}

/* Links inside the dropdown */
.dropdown-content a {
  font-family:FormaDJRMicro-Regular;
  color: #212121;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  font-size: 15px;
  display: flex;
  text-align: left;
  justify-content: left;
  justify-items: left;
}

.dropbtnUserName {
  background: #fff;
}

.dropdown-contentUserName a {
  white-space: nowrap;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color:#014667;
  color: #fff;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown #3CAE4C */

.dropdown:hover .dropbtnUserName {
  background-color: #ffffff;
  color: #212121;
}

.custemerNameAlignment {
  word-break: normal;
  white-space: normal;
  text-align: center;
}