.statelliteContentContainer{
    z-index: 0;
    position: relative;
    top: 250px;
    width: 100%;
    white-space: nowrap;
    height: 100%;
    margin-bottom: 300px;

}

#contentContainerWrapper {	
    width: 1280px;
    margin: auto;
    padding-left: 200px;
}