
.deviceSearchResultsContainerWrapper {
  margin:auto; 
	width:1366px;
	padding-left:100px ;
	padding-top: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.deviceContentContainerWrapper-SearchResults{
    z-index: 0;
	position:relative; 
	top:10px; 
	width: 100%; 
	white-space: nowrap;
	margin: auto;
}

.deviceCardContainer {
  display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	margin: auto;
  // align-items: center;
}


#deviceListContainer {
  white-space: normal;
	background: #ffffff; 
	-webkit-box-shadow: 0px 0px 7px 7px rgba(0,0,0,0.08); 
	box-shadow: 0px 0px 7px 7px rgba(0,0,0,0.08); 
	border-radius: 16px; 
	border: 2px solid #ffffff;
	margin: 24px;
	max-width: 303px;
	padding: 0 0 24px 0;
}

.alignLoader {
  margin-top: 350px;
  margin-left: 950px;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 9;
  position: absolute;
}

.deviceDetailsOrionDisclaimer {
  position: relative; 
  top: -30px;
	margin:auto;
	text-align: center;
	font-family: FormaDJRMicro-MediumItalic;
	font-size:18px;
	color:#D06702;
}