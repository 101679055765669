html,
body,
ul,
ol {
  margin: 0;
  padding: 0;
}
body {
  font-size: 16px;
  color: $gray-dark;
  background: $white
}
* {
  box-sizing: border-box;
}
::selection {
  background: $primary-green;
  color: $white;
}
::-moz-selection {
  background: $primary-green;
  color: $white;
  text-shadow: none;
}
// Number Input Styles
input[type="number"] {
  -moz-appearance: textfield;
  margin: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
// Searchbox Styles
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
strong {
  font-weight: 700;
}
a {
  text-decoration: none;
  font-family:'HP Simplified Light', 'HP Simplified Regular', 'HP Simplified';
  font-weight:300;
  font-style:normal;
  font-size:16px;
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
ul {
  list-style: none;
}
button {
  height: 36px;
  border-radius: 5px;
  text-align: center;
  color: $white;
  background: $primary-blue;
  border: 0;
  font-weight: 400;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &.block {
    display: block;
    width: 100%;
  }
}
link {
  cursor: pointer;
}

input,
select {
  font-size: inherit;
  font-family: inherit;
}

form {
  margin: 0;
}
