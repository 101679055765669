
.device-wrapper {
  position: fixed;
	top: 65px;
	width:100%;
	height:65px;
  z-index: 1;
}

.deviceDetailsWrapperContainer {
  margin: auto;
  width: 1366px;
}

#deviceCardDetailsContainer {
    margin: auto;
    position: fixed;
    width: 100%;
    top: 100px;
}

#deviceDetailsContainer {
  z-index: 1200;
	position:fixed; 
	left:5px; 
	top:62px;  
}

.deviceCardDetailsWrapper {
  position: relative;
  left: -780px;
  top: -20px;
  max-width: 303px; /* percentage width would be 16.2% */
  max-height: 800px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 7px 7px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 7px 7px rgba(0, 0, 0, 0.08);
  border-radius: 8px 8px 8px 8px;
  border: 2px solid #ffffff;
  justify-content: center;
  flex-direction: row;
  margin: auto;
}
