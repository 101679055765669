@media (-webkit-device-pixel-ratio: 1.25) {
    :root {
    zoom: 0.8;
    }}    
@media (-webkit-device-pixel-ratio: 1.0) {
    :root {
    zoom: 1.0;
    }} 

@media (-webkit-device-pixel-ratio: 1.50) {
    :root {
    zoom: 0.665;
    }} 

@media (-webkit-device-pixel-ratio: 1.75) {
    :root {
    zoom: 0.57;
    }}

@media (-webkit-device-pixel-ratio: 1.1) {
    :root {
    zoom: 0.91;
    }}

@media (-webkit-device-pixel-ratio: 2.0) {
    :root {
    zoom: 0.5;
    }}