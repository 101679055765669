

.leftcard {
    margin-top: 101px;
    position: fixed;
    width: 330px;
    margin-left: 37px;
    border: 10px 1opx solid;
    height: 405px;
    box-shadow: 5px 10px #888888;
}

.instructions {
    position: absolute;
    top: 103px;
    left: 960px;
}