
#deviceSearchSectionContainerWrapper  {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 330px;
    background: linear-gradient(270deg, #027AAE 0%, #3AB4EB 100%);
	margin:auto;
}

.deviceSerachSectionContainer {
    justify-content: space-between; 
	flex-flow:row nowrap; 
	align-items: center;
    position: fixed;
    top: 101px;
    width: 100%;
    height: 92px;
    font-family: 'HP Simplified';
    font-weight: 400;
    font-style: normal;
	margin:auto;
}

.deviceSectionContainerText {
    justify-content: center;
	text-align:center;
    align-items: center;
	margin:auto;
	height:65px;
    font-family: FormaDJRMicro-Regular; 
    font-style: normal;
    font-size: 28px;
    color: #ffffff;
    position: absolute;
    top: 30px;
    width: 100%;
    word-wrap: break-word;
    line-height: 32px;
}

.deviceSearchItem1 {
	align-items: center;
	justify-content: center;
	
}

.deviceParagraphSectionContainer {
    position: absolute;
    width: 100%;
    height: 73px;
    font-family: FormaDJRMicro-Regular;
    top: 73px;
    font-style: normal;
    font-size: 18px;
    color: #ffffff;
    justify-content: center;
	text-align:center;
    align-items: center;
	margin:auto;
}

#deviceContainerWrapper {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 330px;
	margin:auto;
}


.deviceSectionItem{
	align-items: center;
	justify-content: center;
}

.homeContentContainerWrapper {
    display: flex;
    justify-content: center;
    position: relative;
    left: 0;
    top: 0px;
    width: 100%;
    height: 450px;
    background: linear-gradient(270deg, #027AAE 0%, #3AB4EB 100%); 
    margin: auto;
}

.homeContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 700px;
    margin: auto;
}

.homeTitle {
    font-family: FormaDJRMicro-Light; 
    font-size: 30px; 
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    line-height: 24px;
    margin: 70px 0 30px 0;
}

.homeContent {
    font-family: FormaDJRMicro-Regular; 
    letter-spacing: .5px;
    font-size: 22px; 
    color: #ffffff;
    line-height: 22px;
    text-align: center;
    line-height: 28px;
    margin-bottom: 30px;
}

.goToOrion {
    font-family: FormaDJRMicro-MediumItalic; 

}